<template>
    <!-- 文章修改页 -->
    <div class="pdf">
        <div class="content">
            <!-- 标题 -->
            <div class="title">
                {{articleInfo.title}}
            </div>
            <hr>
            <!-- 内容 -->
            <div style="width:100%;" v-html="articleInfo.content"></div>
        </div>
    </div>
</template>
<style>
img{
    max-width: 100% !important;
}
</style>
<style lang="scss" scoped>
    @import "@/assets/css/teacher/article/articlePreview.scss";
</style>

<script>
import articlePreview from "@/assets/js/teacher/article/articlePreview.js";
export default {
    ...articlePreview
};
</script>
