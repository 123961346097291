import { Message } from "element-ui"; //项目已经全局引入element的话可以不单独引入
import VueUeditorWrap from "vue-ueditor-wrap";
import { startLoading, endLoading, sleep } from "@/utils/function";
import API from "@/api/teacher/article/articlePreview"

export default {
    name: "other",
    components: {
        VueUeditorWrap
    },
    data() {
        return {
            // 文章id
            article_id:'',
            // 文章信息
            articleInfo:{},
        };
    },
    created() {
        const self = this;
        self.article_id = Number(this.$router.currentRoute.query.article_id);
        self.getData();
    },
    methods: {
        /**
         * @name: 获取显示数据
         * @author: camellia
         * @date: 2020-12-29 17:32:19
         */
        getData() 
        {
            const self = this;
            // 开启loading
            // startLoading();
            this.$Loading.show()
            let data = {
                id: self.article_id
            };
            // 请求数据
            API.getArtcilePreviewData(data)
                    .then((result)=>{
                        if(result.code > 0)
                        {
                            self.articleInfo = result.data;
                        }
                        // 关闭loading
                        // endLoading(startLoading()); 
                        self.$Loading.hide()
                    })
                    .catch(err => {
                        self.dialogVisible = false;
                        // 关闭loading
                        // endLoading(startLoading()); 
                        self.$Loading.hide()
                    });
            // self.axios
            //     .get("/index.php/backarticle/getArtcilePreviewData", {
            //         params: data
            //     })
            //     .then(result => {
            //         self.articleInfo = result.data.data;
            //         // 关闭loading
            //         endLoading(startLoading()); 
            //     })
            //     .catch(err => {});
        }
    }
};
